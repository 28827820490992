import { forwardRef } from "react";

import { colors } from "@aprosoja/styles/tokens";

import * as s from "./styles";
import { VariantProps } from "class-variance-authority";

type TextComponentProps = {
  children: React.ReactNode;
  type?: VariantProps<typeof s.text>["type"];
  color?: keyof typeof colors;
  textAlign?: VariantProps<typeof s.text>["textAlign"];
  id?: string;
  className?: string;
};

export const Text = forwardRef<HTMLSpanElement, TextComponentProps>(
  (
    {
      children,
      type,
      color = "th-color-neutral-900",
      textAlign,
      id,
      className,
      ...rest
    },
    ref
  ) => {
    return (
      <span
        id={id}
        ref={ref}
        className={s.text({ type, textAlign, className })}
        style={{ color: colors[color] }}
        {...rest}
      >
        {children}
      </span>
    );
  }
);

Text.displayName = "Text";
