import { cva } from "class-variance-authority";

const buttonVariants = cva(
  "relative font-medium transition-all duration-200 ease-in-out focus:outline-none focus:ring-2 focus:duration-100 rounded-border-radius-small",
  {
    variants: {
      size: {
        small: "h-size-x7 px-size-x2 text-xs",
        medium: "h-size-x9 px-size-x3 text-sm",
        large: "h-size-x12 px-size-x3 text-base",
      },
      variant: { contained: "", outlined: "", text: "" },
      color: { primary: "", secondary: "", success: "", error: "" },
      disabled: { true: "opacity-40" },
      loading: {
        true: "min-w-auto !text-transparent",
      },
    },
    compoundVariants: [
      // Contained
      {
        variant: "contained",
        color: "primary",
        className:
          "bg-th-primary-color-main text-th-content-primary-light focus:ring-th-primary-color-lightest hover:bg-th-primary-color-darkest",
      },
      {
        variant: "contained",
        color: "secondary",
        className:
          "bg-th-tertiary-color-main text-th-content-tertiary-main focus:ring-th-tertiary-color-light hover:bg-th-tertiary-color-light",
      },
      {
        variant: "contained",
        color: "success",
        className:
          "bg-th-success-dark text-th-content-feedback-light focus:ring-th-success-light hover:opacity-80",
      },
      {
        variant: "contained",
        color: "error",
        className:
          "bg-th-error-dark text-th-content-feedback-light focus:ring-th-error-light hover:opacity-80",
      },

      // Outlined
      {
        variant: "outlined",
        color: "primary",
        className:
          "border-th-primary-color-main text-th-primary-color-main focus:ring-th-primary-color-main hover:bg-th-content-primary-light border",
      },
      {
        variant: "outlined",
        color: "secondary",
        className:
          "border-th-tertiary-color-light text-th-tertiary-color-light focus:ring-th-tertiary-color-light border hover:bg-[#FFCD0129]",
      },
      {
        variant: "outlined",
        color: "success",
        className:
          "border-th-success-dark text-th-success-dark focus:ring-th-success-dark border hover:bg-[#0B6B3729]",
      },
      {
        variant: "outlined",
        color: "error",
        className:
          "border-th-error-dark text-th-error-dark focus:ring-th-error-dark border hover:bg-[#B2201229]",
      },

      // Text
      {
        variant: "text",
        color: "primary",
        className:
          "text-th-primary-color-main focus:ring-th-primary-color-lightest hover:bg-th-content-primary-light",
      },
      {
        variant: "text",
        color: "secondary",
        className:
          "text-th-tertiary-color-light focus:ring-th-tertiary-color-lightest hover:bg-[#FFCD0129]",
      },
      {
        variant: "text",
        color: "success",
        className:
          "text-th-success-dark focus:ring-th-success-dark hover:bg-[#0B6B3729]",
      },
      {
        variant: "text",
        color: "error",
        className:
          "text-th-error-dark focus:ring-th-error-dark hover:bg-[#B2201229]",
      },

      // Disabled
      {
        variant: "contained",
        disabled: true,
        className: "bg-th-color-neutral-400 text-th-color-neutral-600",
      },
      {
        variant: "outlined",
        disabled: true,
        className: "border-th-color-neutral-600 text-th-color-neutral-600",
      },
      {
        variant: "text",
        disabled: true,
        className: "text-th-color-neutral-400",
      },
    ],
    defaultVariants: {
      size: "medium",
      variant: "contained",
      color: "primary",
      disabled: false,
    },
  }
);

export { buttonVariants };
