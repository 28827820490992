"use client";

import React, { useState } from "react";
import { TabsRoot, TabsList, TabsTrigger, TabsContent } from "./ui/tabs-root";
import { ScrollArea } from "@aprosoja/ui";

export type TabItem = {
  value: string;
  label: string;
  content: React.ReactNode;
};

export type TabsProps = {
  tabs: TabItem[];
  initialActive: string;
  onTabChange?: (active: string) => void;
};

export const Tabs: React.FC<TabsProps> = ({
  tabs,
  initialActive,
  onTabChange,
}) => {
  const [active, setActive] = useState(initialActive);

  const handleTabChange = (value: string) => {
    setActive(value);
    if (onTabChange) {
      onTabChange(value);
    }
  };

  return (
    <TabsRoot
      value={active}
      onValueChange={handleTabChange}
      className="h-[calc(100vh-150px)]"
    >
      <TabsList>
        {tabs.map((tab) => (
          <TabsTrigger key={tab.value} value={tab.value}>
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      <ScrollArea className="h-full pr-2">
        {tabs.map((tab) => (
          <TabsContent key={tab.value} value={tab.value}>
            {tab.content}
          </TabsContent>
        ))}
      </ScrollArea>
    </TabsRoot>
  );
};
