import { cva } from "class-variance-authority";

export const container = cva(
  "bg-th-color-neutral-800 border-border-width-none rounded-border-radius-tiny py-size-base px-size-x2 gap-size-x2 data-[state=delayed-open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=delayed-open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=delayed-open]:zoom-in-95  data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 flex items-center gap-x-2 max-w-[320px]"
);
export const text = cva(
  "text-th-color-neutral-0 font-default text-small leading-medium tracking-small"
);
export const supportText = cva(
  "text-th-color-neutral-200 font-default text-small leading-medium tracking-small"
);
