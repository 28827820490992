// toRem - convert px to rem
/**
 * Convert px to rem unit
 *
 * @param {string} value
 * @returns A string with the value converted to rem
 *
 * @example
 * rem('16') // '1rem'
 */
export function rem(value: string | number): string {
  const px = parseFloat(value.toString());
  const rem = px / 16;

  return `${rem}rem`;
}
