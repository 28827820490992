import { cva } from "class-variance-authority";

const outline = cva(
  "flex items-center justify-center rounded-border-radius-full focus-within:outline-0",
  {
    variants: {
      size: {
        small: "h-size-x8 w-size-x8",
        medium: "h-size-x10 w-size-x10",
      },
      disabled: {
        true: "bg-transparent !important",
        false:
          "hover:bg-th-primary-color-lightest-16 active:bg-th-primary-color-lightest-32 focus-within:bg-th-primary-color-lightest-24",
      },
    },
  }
);

const root = cva(
  "bg-th-bg-light data-[state=checked]:bg-primary peer shrink-0 rounded-border-radius-tiny",
  {
    variants: {
      isChecked: {
        true: "bg-th-primary-color-main border-none disabled:bg-th-color-neutral-300",
        false: "border border-neutral-300",
        indeterminate: "bg-th-primary-color-main border-none",
      },
      size: {
        small: "h-size-x4 w-size-x4",
        medium: "h-size-x6 w-size-x6",
      },
      disabled: {
        true: "cursor-default",
      },
    },
    compoundVariants: [
      {
        isChecked: false,
        disabled: true,
        class: "opacity-level-4",
      },
      {
        isChecked: true,
        disabled: true,
        class: "bg-neutral-300",
      },
    ],
  }
);

export { root, outline };
