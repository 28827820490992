import * as React from 'react';

import * as s from './styles';

interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
}

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(
  ({ className, ...props }, ref) => <h3 ref={ref} className={s.title({ className })} {...props} />,
);
CardTitle.displayName = 'CardTitle';

export const Card = React.forwardRef<HTMLDivElement, CardProps>(({ className, title, children, ...props }, ref) => (
  <div ref={ref} className={s.container({ className })} {...props}>
    {title && <CardTitle>{title}</CardTitle>}
    {children}
  </div>
));
Card.displayName = 'Card';
