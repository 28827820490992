"use client";
import React, { Children, type ReactNode } from "react";
import * as Toast from "@radix-ui/react-toast";

import { useToastFeedbackUser } from "@aprosoja/stores";
import { ToastFeedbackUseType } from "@aprosoja/types";

import * as s from "./styles";
import { Text } from "../text/text";

type BundleFeedbackUserType = {
  children?: ReactNode;
};

const MemoizedToastRoot = React.memo(
  ({
    description,
    id,
    title,
    status = "notification",
  }: ToastFeedbackUseType) => {
    const { removeToast } = useToastFeedbackUser();

    return (
      <Toast.Root
        key={id}
        id={id}
        className={s.toastVariants({ status })}
        onOpenChange={(isOpen) => {
          if (!isOpen) {
            removeToast(id);
          }
        }}
      >
        <div className="flex items-stretch">
          <hr className={s.lineVariants({ status })} />
        </div>
        <div className="grid gap-size-base py-size-x2 flex-1">
          {title && (
            <Toast.Title>
              <Text type="headingXS">{title}</Text>
            </Toast.Title>
          )}
          {description && (
            <Toast.Description>
              <Text type="paragraphS">{description}</Text>
            </Toast.Description>
          )}
        </div>
      </Toast.Root>
    );
  }
);

export const BundleFeedbackUser = React.memo(
  ({ children }: BundleFeedbackUserType) => {
    const { toast } = useToastFeedbackUser();

    return (
      <Toast.Provider>
        {Children.toArray(
          toast?.map((toastItem) => <MemoizedToastRoot {...toastItem} />)
        )}
        <Toast.Viewport className={s.viewport()} />
        {children}
      </Toast.Provider>
    );
  }
);

MemoizedToastRoot.displayName = "Toast";

BundleFeedbackUser.displayName = "ToastProvider";
