import { cva } from "class-variance-authority";

export const badgeVariants = cva(
  `w-fit inline-flex items-center justify-center rounded-border-radius-small border px-size-x2
  py-[5.5px] transition-colors focus:outline-none focus:ring-2 focus:ring-ring
  focus:ring-offset-2`,
  {
    variants: {
      variant: {
        news: "border-th-secondary-color-dark bg-th-secondary-color-lightest",
        positive: "border-th-primary-color-main bg-th-primary-color-lightest",
        negative: "border-th-error bg-[#FFCCCC]",
      },
    },
  }
);
