import { Children } from "react";

import { Pagination } from "./pagination";
import {
  Table as UITable,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "./ui/table";

export type TableColumn<T> = {
  label: string;
  name: keyof T;
  width?: string;
};

export type TableProps<T> = {
  data: T[];
  columns: TableColumn<T>[];
  renderCell: <K extends keyof T>(item: T, column: K) => JSX.Element;
  totalCount: number;
  currentPage: number;
  onPageChange: (page: number) => void;
  rowsPerPage?: number;
  variant?: "primary" | "secondary";
};

export const Table = <T,>({
  columns,
  data,
  renderCell,
  currentPage,
  onPageChange,
  totalCount,
  rowsPerPage = 8,
  variant = "primary",
}: TableProps<T>) => {
  return (
    <div className="flex flex-col w-full gap-size-x4">
      <div
        className={`flex flex-col gap-size-x6 ${
          variant === "primary"
            ? "p-size-x6  bg-th-color-neutral-0 rounded-border-radius-large"
            : ""
        }`}
      >
        <UITable>
          <TableHeader>
            <TableRow
              className={`${variant === "primary" ? "border-b " : "border-none"}`}
            >
              {Children.toArray(
                columns.map((column) => (
                  <TableHead
                    className={`w-[${column.width}px] h-size-x10
                    ${
                      variant === "primary"
                        ? "font-bold text-th-color-neutral-900 px-4 h-12 "
                        : "font-medium text-th-color-neutral-400 px-1 h-8"
                    } `}
                  >
                    {column.label}
                  </TableHead>
                ))
              )}
            </TableRow>
          </TableHeader>
          <TableBody>
            {data.map((item, index) => (
              <TableRow
                key={index}
                className="item-center text-th-color-neutral-900 border-b-0"
              >
                {Children.toArray(
                  columns.map((column) => (
                    <TableCell
                      className={`text-th-color-neutral-700 ${variant === "primary" ? "p-4" : "py-[5px] px-1"}`}
                    >
                      {renderCell(item, column.name)}
                    </TableCell>
                  ))
                )}
              </TableRow>
            ))}
          </TableBody>
        </UITable>
      </div>
      <Pagination
        totalCount={totalCount}
        currentPage={currentPage}
        onPageChange={onPageChange}
        pageSize={rowsPerPage}
      />
    </div>
  );
};
