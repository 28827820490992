import { cva } from 'class-variance-authority';

export const selectWrapper = cva(
  `min-h-size-x12 min-w-[250px] flex relative border border-th-color-neutral-200 rounded-border-radius-small
  items-center pl-size-x3 py-[11px] bg-transparent h-fit flex-wrap hover:border-th-primary-color-light`,
  {
    variants: {
      isFocused: {
        true: 'border-th-primary-color-light',
      },
      hasTags: {
        true: 'pl-size-x2',
      },
      error: {
        true: 'border-th-error',
      },
      disabled: {
        true: 'opacity-32',
      },
    },
  },
);

export const input = cva(
  `w-full h-full bg-transparent font-medium text-medium text-th-color-neutral-800 z-2
  placeholder:text-th-color-neutral-200`,
  {
    variants: {
      isFocused: {
        true: 'placeholder:text-th-color-neutral-200',
      },
    },
  },
);

export const dropdownContainer = cva(
  `w-full max-h-64 pt-size-x2 absolute bg-th-color-neutral-0
  rounded-border-radius-small shadow-lg overflow-y-auto z-10`,
);

export const option = cva(
  `flex max-w-full w-full h-size-x8 cursor-pointer items-center pl-size-x4
  gap-size-x2 hover:bg-th-color-neutral-100 text-ellipsis whitespace-nowrap`,
  {
    variants: {
      highlighted: {
        true: 'bg-th-color-neutral-100',
      },
    },
  },
);
