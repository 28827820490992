import * as SwitchPrimitives from "@radix-ui/react-switch";

import * as s from "./styles";
import { Text } from "../text/text";

type SwitchProps = {
  checked: boolean | undefined;
  onSwitch: (value: boolean) => void;
  label?: string;
  defaultValue?: boolean;
};

export const Switch = ({
  checked,
  onSwitch,
  label,
  defaultValue,
}: SwitchProps) => {
  return (
    <div className={s.container()}>
      <SwitchPrimitives.Root
        className={s.switchRoot()}
        defaultChecked={defaultValue}
        checked={checked}
        onCheckedChange={onSwitch}
      >
        <SwitchPrimitives.SwitchThumb className={s.switchThumb()} />
      </SwitchPrimitives.Root>
      {label && <Text type="labelL">{label}</Text>}
    </div>
  );
};
