"use client";

import { usePathname } from "next/navigation";
import Link from "next/link";

import { Icon, Text } from "../index";

type BreadcrumbItem = {
  label: string;
  link?: string;
  filterParam?: string;
};

type BreadcrumbProps = {
  segments?: { label: string; path: string }[];
};

const Breadcrumb = ({ segments = [] }: BreadcrumbProps) => {
  const pathname = usePathname();
  const pathSegments = pathname.split("/").filter((segment) => segment !== "");

  const lastSegment = pathSegments[pathSegments.length - 1];
  const lastSegmentLabel =
    segments.find((s) => s.path === lastSegment)?.label || lastSegment;

  const breadcrumbItems: BreadcrumbItem[] = [
    { label: "Home", link: "/" },
    ...segments
      .filter((segment) => segment.path !== lastSegment)
      .map((segment) => ({
        label: segment.label,
        link: `/${segment.path}`,
      })),
    {
      label: lastSegmentLabel,
      link: pathname,
    },
  ];

  return (
    <ul className={`w-full flex items-center gap-size-base h-size-x6`}>
      {breadcrumbItems.map((item, index) => {
        const isActive = item.link === pathname;
        return (
          <li
            className="flex items-center gap-size-x2 first-letter:capitalize"
            key={index}
          >
            {item.link ? (
              <Text
                type={isActive ? "labelS" : "paragraphS"}
                color={
                  isActive ? "th-primary-color-main" : "th-color-neutral-400"
                }
              >
                <Link href={item.link}>{item.label}</Link>
              </Text>
            ) : (
              <Text
                type={isActive ? "labelS" : "paragraphS"}
                color={
                  isActive ? "th-primary-color-main" : "th-color-neutral-400"
                }
              >
                {item.label}
              </Text>
            )}

            {index < breadcrumbItems.length - 1 && (
              <Icon
                name="arrow_forward_ios"
                size="size-x3"
                color={
                  isActive ? "th-primary-color-main" : "th-color-neutral-400"
                }
              />
            )}
          </li>
        );
      })}
    </ul>
  );
};

export { Breadcrumb };
