import { cva } from 'class-variance-authority';

export const selectItemClassName = cva(
  'pl-size-x3 hover:bg-th-bg-light focus:bg-th-bg-light flex w-full max-w-[var(--radix-select-trigger-width)] cursor-pointer items-center justify-between overflow-hidden bg-white',
  {
    variants: {
      size: {
        medium: 'text-medium h-size-x8',
        large: 'text-default h-size-x12',
      },
    },
  },
);

export const selectItemImageClassName = cva('rounded-full', {
  variants: {
    size: {
      medium: 'h-size-x5 w-size-x5',
      large: 'h-size-x6 w-size-x6',
    },
  },
});
