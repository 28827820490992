import { ReactNode } from "react";
import Image from "next/image";
import * as DialogPrimitive from "@radix-ui/react-dialog";

import { Button } from "../index";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "./ui/dialog";

import alertIcon from "../../public/alert.svg";
import successIcon from "../../public/success.svg";
import * as s from "./styles";

type ModalProps = DialogPrimitive.DialogProps & {
  trigger?: ReactNode;
  title?: string;
  description?: ReactNode;
  confirmButtonText?: string;
  closeButtonText?: string;
  variant?: "alert-action" | "alert-message" | "delete" | "success-message";
  onConfirm?: () => void;
  loadingButtonConfirm?: boolean;
  onClose?: () => void;
  disabledButtonConfirm?: boolean;
};

const Modal = ({
  title,
  description,
  trigger,
  closeButtonText,
  onConfirm,
  confirmButtonText,
  variant = "delete",
  loadingButtonConfirm = false,
  disabledButtonConfirm = false,
  onClose,
  ...rest
}: ModalProps) => {
  return (
    <Dialog {...rest}>
      <DialogTrigger asChild>{trigger}</DialogTrigger>
      <DialogContent className={s.modalContentVariants({ variant })}>
        <DialogHeader className="flex flex-col items-center gap-size-x6">
          <DialogTitle className="flex flex-col items-center gap-size-x6 text-large font-bold text-center">
            {variant !== "delete" ? (
              <Image
                src={variant === "success-message" ? successIcon : alertIcon}
                alt="alert sign"
              />
            ) : null}
            {title && title}
          </DialogTitle>
        </DialogHeader>
        <DialogDescription className="text-default font-normal text-center">
          {description}
        </DialogDescription>
        <DialogFooter className="w-full flex justify-center items-center gap-size-x4">
          {closeButtonText && (
            <DialogClose asChild>
              <Button
                onClick={onClose}
                variant={(variant === "alert-message" && "outlined") || "text"}
                color={variant === "alert-message" ? "error" : undefined}
                size="large"
                className={`${
                  confirmButtonText
                    ? "w-full text-neutral-700"
                    : "w-full max-w-[120px]"
                } rounded-border-radius-small`}
              >
                {closeButtonText}
              </Button>
            </DialogClose>
          )}

          {confirmButtonText && (
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={onConfirm}
              className={"w-full rounded-border-radius-small"}
              loading={loadingButtonConfirm}
              disabled={disabledButtonConfirm}
            >
              {confirmButtonText}
            </Button>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export { Modal };
