"use client";
import React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

import * as S from "./select-content.recipe";

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={S.selectContentClassName({ position })}
      position={position}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={S.selectViewportClassName({ position })}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

export { SelectContent };
