import { cva } from "class-variance-authority";

export const selectContentClassName = cva(
  "bg-popover text-popover-foreground data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 relative z-50  max-h-96 overflow-hidden border shadow-md",
  {
    variants: {
      position: {
        popper:
          "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
        "item-aligned": "",
      },
    },
  }
);

export const selectViewportClassName = cva("", {
  variants: {
    position: {
      popper:
        "h-[var(--radix-select-trigger-height)] w-[calc(var(--radix-select-trigger-width)-2px)] max-w-[calc(var(--radix-select-trigger-width)-2px)]",
      "item-aligned": "",
    },
  },
});
