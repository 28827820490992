import {
  AccordionContent,
  AccordionItem,
  AccordionRoot,
  AccordionTrigger,
} from "./ui/accordion-ui";

export type AccordionTypes = {
  content: React.ReactNode;
  trigger: React.ReactNode;
};

export const Accordion = ({ content, trigger }: AccordionTypes) => {
  return (
    <AccordionRoot type="single" collapsible>
      <AccordionItem value="item-1">
        <AccordionTrigger>{trigger}</AccordionTrigger>
        <AccordionContent>{content}</AccordionContent>
      </AccordionItem>
    </AccordionRoot>
  );
};
