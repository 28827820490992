import "material-symbols";
import { forwardRef } from "react";

import { colors, sizes } from "@aprosoja/styles/tokens";

import { type IconProps } from "./icon.types";

const Icon = forwardRef<HTMLSpanElement, IconProps>(
  (
    {
      color = "th-primary-color-main",
      customColor,
      disabled = false,
      fill = false,
      name,
      size = "size-x6",
      weight = 400,
      variant = "outlined",
    },
    ref
  ) => {
    return (
      <span
        data-testid="icon"
        className={`material-symbols-${variant} select-none${disabled ? " opacity-40" : ""}`}
        ref={ref}
        style={{
          fontVariationSettings: `'opsz' 24, 'FILL' ${fill ? 1 : 0}, 'wght' ${weight}`,
          fontSize: sizes[size],
          color: customColor ?? colors[color],
        }}
      >
        {name}
      </span>
    );
  }
);

Icon.displayName = "Icon";

export { Icon };
