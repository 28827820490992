import { Editor } from '@tiptap/react';

export const toggleOptions = (editor: Editor) => {
  return [
    {
      value: 'bold',
      iconName: 'format_bold',
      onClick: () => !editor?.chain().focus().toggleMark('bold').run(),
    },
    {
      value: 'italic',
      iconName: 'format_italic',
      onClick: () => !editor?.chain().focus().toggleMark('italic').run(),
    },
    {
      value: 'underline',
      iconName: 'format_underlined',
      onClick: () => !editor?.chain().focus().toggleMark('underline').run(),
    },
    {
      value: 'strike',
      iconName: 'format_strikethrough',
      onClick: () => !editor?.chain().focus().toggleMark('strike').run(),
    },
    {
      value: 'ordered',
      iconName: 'format_list_numbered',
      onClick: () => !editor.chain().focus().toggleOrderedList().run(),
    },
    {
      value: 'bulleted',
      iconName: 'format_list_bulleted',
      onClick: () => !editor?.chain().focus().toggleBulletList().run(),
    },
    {
      value: 'h1',
      iconName: 'format_h1',
      onClick: () => !editor.chain().focus().toggleHeading({ level: 1 }).run(),
    },
    {
      value: 'h2',
      iconName: 'format_h2',
      onClick: () => !editor.chain().focus().toggleHeading({ level: 2 }).run(),
    },
    {
      value: 'h3',
      iconName: 'format_h3',
      onClick: () => !editor.chain().focus().toggleHeading({ level: 3 }).run(),
    },
    {
      value: 'h4',
      iconName: 'format_h4',
      onClick: () => !editor.chain().focus().toggleHeading({ level: 4 }).run(),
    },
    {
      value: 'paragraph',
      iconName: 'format_paragraph',
      onClick: () => !editor?.chain().focus().setParagraph().run(),
    },
    {
      value: 'image',
      iconName: 'image',
      onClick: () => {},
    },
  ];
};
