import * as React from "react";
import { type VariantProps } from "class-variance-authority";

import { colors } from "@aprosoja/styles/tokens";
import { cn } from "@aprosoja/utils";
import { Text } from "@aprosoja/ui";

import * as s from "./styles";

type CustomColorKeys = keyof typeof colors;

const colorMap: Record<NonNullable<BadgeProps["variant"]>, CustomColorKeys> = {
  positive: "th-primary-color-main",
  negative: "th-error",
  news: "th-secondary-color-dark",
};

interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof s.badgeVariants> {
  variant?: "positive" | "negative" | "news";
  children: string;
}

export function Badge({
  className,
  variant = "news",
  children,
  ...props
}: BadgeProps) {
  const color = colorMap[variant!];

  return (
    <div className={cn(s.badgeVariants({ variant }), className)} {...props}>
      <Text type="labelS" color={color}>
        {children}
      </Text>
    </div>
  );
}
