import { cva } from 'class-variance-authority';

export const container = cva('flex items-center gap-size-x3 w-full');

export const switchRoot = cva(
  `w-size-x10 border-[1px] border-th-color-neutral-900 p-size-base rounded-border-radius-large
  cursor-pointer data-[state=checked]:bg-th-primary-color-main data-[state=checked]:border-transparent`,
);

export const switchThumb = cva(
  `block h-size-x4 w-size-x4 rounded-border-radius-full bg-th-color-neutral-900
  transform transition-transform duration-200 translate-x-0 hover:shadow-lg
  active:shadow-lg data-[state=checked]:bg-th-color-neutral-0 data-[state=checked]:translate-x-4`,
);
