import type { IconButtonSize } from '../icon-button.types';

interface LoadingProps {
  size: IconButtonSize;
}

export function Loading({ size }: LoadingProps) {
  const sizeMap = {
    small: 'h-3 w-3',
    medium: 'h-4 w-4',
    large: 'h-5 w-5 ',
  };

  return (
    <div
      className={`${sizeMap[size]} text-primary absolute inset-0 m-auto animate-spin rounded-full border-2 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]`}
      role="status"
    ></div>
  );
}
