"use client";
import React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

import { colors } from "@aprosoja/styles/tokens";

import * as s from "./select-item.recipe";
import type { SelectItemProps } from "./select-item.types";
import { Icon } from "../../icon/icon";

type ColorToken = keyof typeof colors;

const getColorFromToken = (token: ColorToken) => {
  return colors[token] || "#FFFFFF";
};

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  SelectItemProps
>(({ children, option, size, className, ...props }, ref) => {
  const backgroundColor = getColorFromToken(option.value as ColorToken);
  return (
    <SelectPrimitive.Item
      ref={ref}
      className={s.selectItemClassName({ size, className })}
      {...props}
    >
      <SelectPrimitive.ItemText>
        <div className="gap-size-x2 flex w-full max-w-[calc(var(--radix-select-trigger-width)-16px)] items-center overflow-hidden">
          {!!option.leadingPhoto && (
            <span
              className={s.selectItemImageClassName({
                size,
              })}
              style={{
                backgroundColor,
              }}
            />
          )}
          {!!option.leadingIcon && (
            <Icon
              name={option.leadingIcon}
              size={size === "medium" ? "size-x5" : "size-x6"}
            />
          )}
          <span className="overflow-hidden text-ellipsis whitespace-nowrap">
            {children}
          </span>
          {!!option.supportValue && (
            <span className="text-small text-th-color-neutral-500">
              {option.supportValue}
            </span>
          )}
          {!!option.iconRight && (
            <Icon name={option.iconRight} size={"size-x4"} />
          )}
        </div>
      </SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});
SelectItem.displayName = SelectPrimitive.Item.displayName;

export { SelectItem };
