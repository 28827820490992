'use client';

import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';

import * as s from './label.recipe';
import { Icon } from '../icon/icon';
import { LabelProps } from './label.types';

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, supportLabel, icon, required = false, status = 'default', size = 'default', ...props }, ref) => {
    return (
      <LabelPrimitive.Root {...props} ref={ref} className={s.root()}>
        <div className="gap-size-base flex flex-row">
          <LabelPrimitive.Label {...props} className={s.label({ size, status })} />
          {!!required && <LabelPrimitive.Label className={s.requiredChar()}>*</LabelPrimitive.Label>}
          {!!supportLabel && <LabelPrimitive.Label className={s.supportLabel()}>{supportLabel}</LabelPrimitive.Label>}
        </div>
        {!!icon && <Icon name={icon} size="size-x4" />}
      </LabelPrimitive.Root>
    );
  },
);

Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
