import { cva } from "class-variance-authority";

export const toastVariants = cva(
  `group pointer-events-auto relative flex items-stretch gap-size-x3 w-full z-[99999999]
  overflow-hidden rounded-border-radius-small p-size-x4 shadow-lg transition-all
  data-[swipe=cancel]:translate-x-0
  data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)]
  data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)]
  data-[swipe=move]:transition-none data-[state=open]:animate-in
  data-[state=closed]:animate-out data-[swipe=end]:animate-out
  data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-left-full
  data-[state=open]:slide-in-from-right-full data-[state=open]:sm:slide-in-from-right-full`,
  {
    variants: {
      status: {
        success: "bg-th-primary-color-lightest",
        error: "bg-th-color-neutral-0",
        alert: "bg-th-secondary-color-lightest",
        notification: "bg-th-color-neutral-0",
      },
    },
    defaultVariants: {
      status: "notification",
    },
  }
);

export const lineContainer = cva("flex items-stretch");

export const lineVariants = cva(
  "h-full self-stretch w-size-base rounded-border-radius-tiny ",
  {
    variants: {
      status: {
        success: "bg-th-primary-color-main",
        error: "bg-th-error",
        alert: "bg-th-secondary-color-main",
        notification: "bg-th-info",
      },
    },
    defaultVariants: {
      status: "notification",
    },
  }
);

export const viewport = cva(
  `fixed bottom-0 right-0 z-9999999 flex max-h-full w-full flex-col-reverse p-4
  sm:w-full sm:max-w-[420px] outline-none list-none`
);
