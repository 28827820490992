import { cva } from "class-variance-authority";

export const container = cva(
  `p-size-x2 flex items-center flex-nowrap gap-size-base
  bg-th-primary-color-light rounded-border-radius-small w-fit`
);

export const buttonClose = cva(
  `bg-th-primary-color-lightest p-size-base rounded-border-radius-full w-size-x5
h-size-x5 flex items-center justify-center hover:bg-th-primary-color-light transition-all duration-150 ease-in-out`
);
