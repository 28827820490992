"use client";

import React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { TooltipProps } from "./tooltip.types";
import * as s from "./tooltip.recipe";

const TooltipProvider = TooltipPrimitive.Provider;
const TooltipRoot = TooltipPrimitive.Root;
const TooltipTrigger = TooltipPrimitive.Trigger;
const TooltipContent = TooltipPrimitive.Content;

const Tooltip = React.forwardRef<
  React.ElementRef<typeof TooltipContent>,
  React.ComponentPropsWithoutRef<typeof TooltipContent> & TooltipProps
>(
  (
    {
      text,
      supportText,
      className,
      asChild = false,
      sideOffset = 4,
      children,
      ...props
    },
    ref
  ) => (
    <TooltipProvider>
      <TooltipRoot>
        <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
        <TooltipContent
          ref={ref}
          sideOffset={sideOffset}
          className={s.container()}
          {...props}
        >
          <p className={s.text()}>{text}</p>
          {supportText && <p className={s.supportText()}>{supportText}</p>}
        </TooltipContent>
      </TooltipRoot>
    </TooltipProvider>
  )
);
Tooltip.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip };
