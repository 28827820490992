import { rem } from "@aprosoja/utils";

export const fontSizes = {
  nano: rem(10),
  small: rem(12),
  medium: rem(14),
  default: rem(16),
  large: rem(20),
  xlarge: rem(24),
  big: rem(32),
  xbig: rem(40),
  hero: rem(48),
  display: rem(64),
  giant: rem(80),
};

export const fontWeights = {
  thin: "300",
  default: "400",
  medium: "500",
  bold: "700",
};

export const letterSpacings = {
  tight: "-0.04em", // -4%
  narrow: "-0.02em", // -2%
  none: "0",
  small: "0.02em", // 2%
  medium: "0.04em", // 4%
  large: "0.08em", // 8%
};

export const lineHeights = {
  default: "100%",
  small: "116%",
  medium: "124%",
  large: "148%",
  big: "172%",
};
