"use client";
import React, { forwardRef, useState } from "react";

import { Label, LabelProps } from "../index";
import * as s from "./styles";

type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  Pick<LabelProps, "supportLabel"> & {
    label?: string;
    errorMessage?: string;
    maxCharacter?: number;
  };

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className = "",
      required,
      disabled,
      label,
      supportLabel,
      errorMessage,
      maxCharacter = undefined,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [charCount, setCharCount] = useState(0);

    const handleInputChange = (
      event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      setCharCount(event.target.value.length);
    };

    return (
      <div className={s.container({ disabled })}>
        {label ? (
          <Label
            htmlFor="textarea"
            status={isFocused ? "focused" : "default"}
            required={required}
            supportLabel={supportLabel}
            size="large"
          >
            {label}
          </Label>
        ) : null}
        <div
          className={s.textarea({
            status: isFocused ? "focused" : "default",
            error: !!errorMessage,
            className,
          })}
        >
          <textarea
            ref={ref}
            id="textarea"
            disabled={disabled}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            className={s.input()}
            maxLength={maxCharacter}
            onInput={handleInputChange}
            {...props}
          />
        </div>
        {maxCharacter && (
          <span className="text-[10px] text-th-color-neutral-400 self-end">
            {charCount}/{maxCharacter}
          </span>
        )}

        <div className={s.helperContainer({ error: !!errorMessage })}>
          {errorMessage && <span>{errorMessage}</span>}
        </div>
      </div>
    );
  }
);

export { Textarea };
