import { cva } from "class-variance-authority";

const iconButtonVariants = cva(
  "flex relative text-center justify-center items-center transition-all duration-200 ease-in-out focus:duration-100",
  {
    variants: {
      size: {
        small: "h-size-x7 w-size-x7",
        medium: "h-size-x10 w-size-x10",
        large: "h-size-x12 w-size-x12",
      },
      variant: { contained: "", outlined: "", ghost: "" },
      disabled: { true: "opacity-40 cursor-default" },
    },
    compoundVariants: [
      // Contained
      {
        variant: "contained",
        className:
          "bg-th-primary-color-main text-th-content-primary-light focus:ring-1 focus:ring-th-primary-color-lightest hover:bg-th-primary-color-darkest active:bg-th-primary-color-main",
      },

      // Outlined
      {
        variant: "outlined",
        className:
          "border-th-primary-color-main text-th-primary-color-main focus:ring-1 focus:ring-th-primary-color-main border hover:bg-th-primary-color-main hover:bg-opacity-30",
      },

      // Text
      {
        variant: "ghost",
        className:
          "text-th-primary-color-main focus:ring-1 focus:ring-th-primary-color-lightest hover:bg-th-primary-color-main hover:bg-opacity-30",
      },

      // Disabled
      {
        variant: "contained",
        disabled: true,
        className: "hover:bg-initial focus:ring-0",
      },
      {
        variant: "outlined",
        disabled: true,
        className: "hover:bg-initial focus:ring-0",
      },
      {
        variant: "ghost",
        disabled: true,
        className: "hover:bg-initial focus:ring-0",
      },
    ],
  }
);

export { iconButtonVariants };
