"use client";
import { forwardRef, Children } from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

import type { SelectProps } from "./select.types";
import { SelectContent } from "./select-content/select-content";
import { SelectTrigger } from "./select-trigger/select-trigger";
import { SelectItem } from "./select-item/select-item";
import { Label } from "../label/label";
import { Icon } from "../icon/icon";

const SelectRoot = SelectPrimitive.Root;
const SelectValue = SelectPrimitive.Value;

const Select = forwardRef<HTMLButtonElement, SelectProps>(
  (
    {
      disabled,
      errorMessage,
      label,
      options,
      placeholder,
      required,
      size = "medium",
      supportLabel,
      value,
      onValueChange,
      triggerClassName,
      itemClassName,
      contentClassName,
    }: SelectProps,
    ref
  ) => {
    return (
      <div className="flex w-full select-none flex-col">
        <Label
          supportLabel={supportLabel}
          required={required}
          size={size === "large" ? "large" : "default"}
        >
          {label}
        </Label>
        <SelectRoot
          disabled={disabled}
          value={value}
          onValueChange={onValueChange}
        >
          <SelectTrigger
            className={triggerClassName}
            size={size}
            disabled={disabled}
            error={!!errorMessage}
            ref={ref}
            aria-label={label}
          >
            <SelectValue
              placeholder={
                <span className="text-th-color-neutral-300">{placeholder}</span>
              }
            />
          </SelectTrigger>
          <SelectContent className={contentClassName}>
            {Children.toArray(
              options?.map((option) => (
                <SelectItem
                  className={itemClassName}
                  option={option}
                  size={size}
                  value={option.value}
                >
                  {option.label}
                </SelectItem>
              ))
            )}
          </SelectContent>
        </SelectRoot>
        {!!errorMessage && (
          <div className="gap-size-base mt-size-base flex items-center">
            <Icon name="error" color="th-error-light" size="size-x3" />
            <span className="text-nano text-th-error-light flex">
              {errorMessage}
            </span>
          </div>
        )}
      </div>
    );
  }
);

Select.displayName = "Select";

export { Select };
