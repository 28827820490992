"use client";

import { Children, ReactNode } from "react";

import {
  DropdownMenuRoot,
  DropdownMenuItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { Text } from "../text/text";

export type DropdownMenuItemsProps = {
  label: string;
  value?: string;
  disabled?: boolean;
  onClick?: () => void;
  isActive?: boolean;
};

type DropdownMenuProps = {
  trigger: ReactNode;
  items: DropdownMenuItemsProps[];
};

export function DropdownMenu({ trigger, items }: DropdownMenuProps) {
  return (
    <DropdownMenuRoot>
      <DropdownMenuTrigger asChild>{trigger}</DropdownMenuTrigger>
      <DropdownMenuContent className="w-fit">
        {Children.toArray(
          items.map((item) => (
            <DropdownMenuItem disabled={item.disabled} onClick={item.onClick}>
              <Text
                type="labelM"
                color={
                  item.isActive
                    ? "th-primary-color-main"
                    : "th-color-neutral-800"
                }
              >
                {item.label}
              </Text>
            </DropdownMenuItem>
          )),
        )}
      </DropdownMenuContent>
    </DropdownMenuRoot>
  );
}
