import React, { useCallback } from "react";
import { Editor } from "@tiptap/react";
import * as ToggleGroup from "@radix-ui/react-toggle-group";

import * as s from "./styles";
import { toggleOptions } from "./handleToggleEditor";
import useEditorHeader from "./use-editor-header";
import { Icon } from "../../../index";

export type ToggleGroupProps = {
  editor: Editor;
};

export const EditorHeader: React.FC<ToggleGroupProps> = ({
  editor,
  ...rest
}: ToggleGroupProps) => {
  const { handleAddImage } = useEditorHeader();
  const toggles = toggleOptions(editor);
  const addImage = useCallback(
    (url: string) => {
      if (url) {
        editor?.chain().focus().setImage({ src: url }).run();
      }
    },
    [editor]
  );

  const styleToggles = toggles.filter(
    (toggle) =>
      !["h1", "h2", "h3", "h4", "paragraph", "ordered", "bulleted"].includes(
        toggle.value
      ) || toggle.value === "image"
  );
  const headingToggles = toggles.filter((toggle) =>
    ["h1", "h2", "h3", "h4", "paragraph"].includes(toggle.value)
  );
  const listToggles = toggles.filter((toggle) =>
    ["ordered", "bulleted"].includes(toggle.value)
  );

  return (
    <div className={s.container()}>
      <ToggleGroup.Root
        {...rest}
        type="multiple"
        className="flex gap-size-base border-r-th-color-neutral-200 border-r-[1px]"
      >
        {styleToggles?.map(({ iconName, value, onClick }, index) => {
          if (value === "image") {
            return (
              <ToggleGroup.ToggleGroupItem
                asChild
                value={value}
                className={s.groupItem()}
                key={index}
              >
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label>
                  <input
                    hidden
                    type="file"
                    onChange={async (v) => {
                      const selectedFile = v.target.files;
                      if (selectedFile) {
                        const fileToLoad = selectedFile[0];
                        const res = await handleAddImage(fileToLoad);
                        if (res && res.url) {
                          addImage(res.url as string);
                        }
                      }
                    }}
                  />
                  <Icon
                    name={iconName}
                    size="size-x6"
                    color="th-color-neutral-800"
                  />
                </label>
              </ToggleGroup.ToggleGroupItem>
            );
          }

          return (
            <ToggleGroup.ToggleGroupItem
              onClick={onClick}
              value={value}
              key={index}
              className={s.groupItem()}
            >
              <Icon
                name={iconName}
                size="size-x6"
                color="th-color-neutral-800"
              />
            </ToggleGroup.ToggleGroupItem>
          );
        })}
      </ToggleGroup.Root>

      <ToggleGroup.Root
        {...rest}
        type="single"
        className="flex gap-size-base border-r-th-color-neutral-200 border-r-[1px]"
      >
        {headingToggles?.map(({ iconName, value, onClick }, index) => (
          <ToggleGroup.ToggleGroupItem
            onClick={onClick}
            value={value}
            key={index}
            className={s.groupItem()}
          >
            <Icon name={iconName} size="size-x6" color="th-color-neutral-800" />
          </ToggleGroup.ToggleGroupItem>
        ))}
      </ToggleGroup.Root>

      <ToggleGroup.Root {...rest} type="single" className="flex gap-size-base">
        {listToggles?.map(({ iconName, value, onClick }, index) => (
          <ToggleGroup.ToggleGroupItem
            onClick={onClick}
            value={value}
            key={index}
            className={s.groupItem()}
          >
            <Icon name={iconName} size="size-x6" color="th-color-neutral-800" />
          </ToggleGroup.ToggleGroupItem>
        ))}
      </ToggleGroup.Root>
    </div>
  );
};
