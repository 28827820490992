export const fileToBase64 = async (file?: File) => {
  if (file) {
    let base64: string | ArrayBuffer | null | undefined = '';
    await new Promise((resolve) => {
      // cria um objeto FileReader
      const reader = new FileReader();

      // lê o arquivo como uma URL de dados
      reader.readAsDataURL(file);

      // define a função de retorno de chamada quando a leitura do arquivo é concluída
      reader.onload = (event) => {
        // o resultado é o arquivo em Base64
        const result = event?.target?.result;
        base64 = result;

        if (base64) {
          resolve(base64); // retorna o arquivo em Base64
        }
      };
    });

    return base64;
  }
};
