"use client";

import { useCallback, useEffect, useState } from "react";
import { useDropzone, type DropzoneProps as Props } from "react-dropzone";
import Image from "next/image";
import Link from "next/link";
import { v4 } from "uuid";

import { useToastFeedbackUser } from "@aprosoja/stores";
import { handleLimitCharacter, imageLoader } from "@aprosoja/utils";

import * as s from "./styles";
import { Text } from "../text/text";
import { Button } from "../button/button";
import { Icon } from "../icon/icon";
import { Label } from "../label/label";

export type DropzoneFile = File & {
  preview?: string;
};

export type ErrorFile = {
  fileName: string;
};

type DropzoneProps = Props & {
  preview?: string;
  error?: string;
  isVideo?: boolean;
  remoteTriggerHandleRemove?: boolean;
  onRemove?: () => void;
  onFile?: (file: File) => void;
  label?: string;
  description?: string;
  variant?: "image" | "file" | "imagefull";
  textTrigger?: string;
  maxSize?: number;
};

const Dropzone = ({
  preview,
  error,
  remoteTriggerHandleRemove,
  onRemove,
  onFile,
  label,
  description,
  variant = "image",
  textTrigger = "Escolher arquivo",
  maxSize = 204800,
  ...rest
}: DropzoneProps) => {
  const [previewFile, setPreviewFile] = useState<string | undefined>(preview);
  const [noClick, setNoClick] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>();
  const { setToast } = useToastFeedbackUser();
  const maxSizeKb = maxSize / 1024;

  const fileValidator = (file: File) => {
    if (file.size > maxSize) {
      setToast({
        id: v4(),
        title: `Ops! Selecione um arquivo com no máximo ${maxSizeKb}kb`,
        status: "error",
      });
    }

    return null;
  };

  const handleDropAccept = (files: File[]) => {
    const newFile = files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );

    setPreviewFile(newFile[0]?.preview);
    setFileName(newFile[0]?.name);
    if (files?.[0]) {
      onFile?.(files[0]);
    }
  };

  const handleRemove = useCallback(() => {
    setPreviewFile(undefined);
    setFileName(undefined);
    setNoClick(false);
    onRemove?.();
  }, [onRemove]);

  const { getRootProps, getInputProps } = useDropzone({
    accept:
      variant === "file"
        ? { "application/pdf": [".pdf"] }
        : { "image/*": [".png", ".jpg", ".jpeg", ".svg"] },
    onDropAccepted: handleDropAccept,
    maxFiles: 1,
    noClick,
    maxSize: maxSize,
    validator: fileValidator,
    ...rest,
  });

  useEffect(() => {
    if (remoteTriggerHandleRemove) {
      handleRemove();
    }
  }, [remoteTriggerHandleRemove, handleRemove]);

  useEffect(() => {
    if (preview) {
      setPreviewFile(preview);
    }
  }, [preview]);

  return (
    <div className="flex flex-col gap-size-x2">
      {label && (
        <Label required size="large">
          {label}
        </Label>
      )}
      {previewFile ? (
        <div
          className={`flex gap-size-x2 ${
            variant !== "file" ? "flex-col" : ""
          } ${variant !== "image" && "items-center"}`}
        >
          {(variant === "image" && (
            <div className={s.wrappedImage()}>
              <Image
                fill
                loader={imageLoader}
                className="w-full h-full object-cover rounded-border-radius-small"
                src={previewFile}
                alt="Imagem"
                priority
              />
            </div>
          )) ||
            (variant === "imagefull" && (
              <div className="w-full h-[280px] relative">
                <Image
                  fill
                  loader={imageLoader}
                  className="w-fit h-full object-contain"
                  src={previewFile}
                  alt="Imagem"
                  priority
                />
              </div>
            )) || (
              <Link href={previewFile} target="_blank">
                <Text type="headingXS" color="th-color-neutral-800">
                  {handleLimitCharacter(fileName, 74)}
                </Text>
              </Link>
            )}
          <div className="w-fit flex gap-size-x4 items-center">
            <div className="w-[200px] grid grid-cols-2 gap-size-x4 items-center">
              <Button
                variant="outlined"
                color="error"
                type="button"
                size="large"
                className="rounded-border-radius-small"
                onClick={handleRemove}
              >
                Excluir
              </Button>

              <div {...getRootProps()}>
                <Button
                  variant="outlined"
                  type="button"
                  size="large"
                  className="rounded-border-radius-small w-full"
                >
                  Editar
                </Button>
                <input hidden {...getInputProps()} />
              </div>
            </div>
            {variant === "image" && (
              <Text type="paragraphM" color="th-color-neutral-700">
                {handleLimitCharacter(fileName, 74)}
              </Text>
            )}
          </div>
        </div>
      ) : (
        <div className={s.wrappedDropzone()}>
          <div {...getRootProps()}>
            <Button
              variant="outlined"
              type="button"
              size="large"
              className="rounded-border-radius-small whitespace-nowrap"
            >
              {textTrigger}
            </Button>
            <input hidden {...getInputProps()} />
          </div>
          <Text type="paragraphM" color="th-color-neutral-700">
            {description}
          </Text>
        </div>
      )}
      {error && (
        <div className="w-full flex items-center gap-size-base">
          <Icon name="alert-circle" color="th-error-dark" size="size-x3" />
          <Text type="labelS" color="th-error">
            {error}
          </Text>
        </div>
      )}
    </div>
  );
};

export { Dropzone };
