import { forwardRef } from "react";

import { iconButtonVariants } from "./icon-button.recipe";
import type { IconButtonProps } from "./icon-button.types";

import { Loading } from "./loading/loading";
import { Icon } from "../icon/icon";

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      className,
      variant = "contained",
      size = "medium",
      disabled = false,
      name,
      loading,
      ...props
    },
    ref
  ) => {
    const handleIconSize = () => {
      switch (size) {
        case "small":
          return "size-x5";
        case "medium":
          return "size-x6";
        case "large":
          return "size-x8";
      }
    };

    return (
      <button
        data-testid="icon-button"
        className={iconButtonVariants({ className, variant, size, disabled })}
        disabled={disabled || loading}
        ref={ref}
        {...props}
      >
        {loading ? (
          <Loading size={size} />
        ) : (
          <Icon
            name={name}
            size={handleIconSize()}
            color={
              variant === "contained"
                ? "th-color-neutral-0"
                : "th-primary-color-main"
            }
          />
        )}
      </button>
    );
  }
);

IconButton.displayName = "IconButton";

export { IconButton, iconButtonVariants };
