import { ReactNode } from "react";

import { Text } from "../text/text";

type HeaderProps = {
  title: string;
  subTitle?: string;
  actionButton?: ReactNode;
};

export function Header({ title, subTitle, actionButton }: HeaderProps) {
  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-baseline gap-size-x2">
        <Text type="headingM">{title}</Text>
        {!!subTitle && <Text type="labelL">{subTitle}</Text>}
      </div>
      {actionButton && actionButton}
    </div>
  );
}
