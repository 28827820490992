"use client";
import { useEffect, useState, forwardRef } from "react";
import { useCombobox, useMultipleSelection } from "downshift";

import * as s from "./styles";
import { Checkbox, Chip, Icon, Label, Text } from "../index";

export type Option = {
  label: string;
  value: string;
};

export type MultiselectProps = {
  options: Option[];
  required?: boolean;
  defaultSelectedOptions?: Option[];
  errorMessage?: string;
  disabled?: boolean;
  label: string;
  placeholder?: string;
  onSelectOptions: (options: Option[]) => void;
};

const Multiselect = forwardRef<HTMLInputElement, MultiselectProps>(
  (
    {
      errorMessage,
      defaultSelectedOptions,
      label,
      placeholder,
      options: propOptions,
      required = false,
      disabled = false,
      onSelectOptions,
    }: MultiselectProps,
    ref
  ) => {
    const [value, setValue] = useState("");
    const [selectedOptions, setSelectedOptions] = useState<Option[]>(
      defaultSelectedOptions || []
    );
    const [options, setOptions] = useState<Option[]>(propOptions);

    const isSelected = (option: Option): boolean => {
      return selectedOptions.some(
        (someOption) => someOption.value === option?.value
      );
    };

    const { getSelectedItemProps, getDropdownProps } = useMultipleSelection({
      defaultActiveIndex: 0,
      selectedItems: selectedOptions,
      onStateChange: ({ selectedItems: newSelectedOptions, type }) => {
        switch (type) {
          case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
          case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
          case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
            setSelectedOptions(newSelectedOptions || []);
            break;
          default:
            break;
        }
      },
    });

    const removeSelectedOption = (option: Option) => {
      setSelectedOptions(
        selectedOptions.filter((selected) => selected.value !== option.value)
      );
    };

    const {
      isOpen,
      getLabelProps,
      getMenuProps,
      getInputProps,
      getItemProps,
      getToggleButtonProps,
      highlightedIndex,
    } = useCombobox({
      items: options,
      selectedItem: null,
      defaultHighlightedIndex: 0,
      itemToString: (item) => (item ? item.label : ""),
      stateReducer: (_, actionAndChanges) => {
        const {
          changes,
          type,
          highlightedIndex: someHighlightedIndex,
        } = actionAndChanges;
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            setValue("");
            return {
              ...changes,
              highlightedIndex: someHighlightedIndex,
              isOpen: true,
              inputValue: "",
            };
          default:
            return changes;
        }
      },
      onStateChange: ({
        inputValue: newValue,
        type,
        selectedItem: newSelectedOption,
      }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
          case useCombobox.stateChangeTypes.InputBlur:
            if (newSelectedOption) {
              if (!isSelected(newSelectedOption)) {
                setSelectedOptions([...selectedOptions, newSelectedOption]);
              } else {
                removeSelectedOption(newSelectedOption);
              }
            }
            setOptions(propOptions);
            break;
          case useCombobox.stateChangeTypes.InputChange:
            setValue(newValue || "");
            break;
          default:
            break;
        }
      },
      onInputValueChange: () => {
        setOptions(
          propOptions.filter((option) =>
            option.label.toLowerCase().includes(value.toLowerCase())
          )
        );
      },
    });

    const renderTags = () => {
      return selectedOptions.map((option, index) => (
        <Chip
          {...getSelectedItemProps({
            index,
            selectedItem: option,
          })}
          key={option.value}
          onClosePress={() => !disabled && removeSelectedOption(option)}
        >
          {option.label}
        </Chip>
      ));
    };

    useEffect(() => {
      onSelectOptions(selectedOptions || []);
    }, [selectedOptions, onSelectOptions]);

    useEffect(() => {
      setOptions(propOptions);
    }, [propOptions]);

    useEffect(() => {
      setSelectedOptions(defaultSelectedOptions || []);
    }, [defaultSelectedOptions]);

    return (
      <div className="relative">
        <Label {...getLabelProps()} required={required}>
          {label}
        </Label>
        <div
          className={s.selectWrapper({
            isFocused: isOpen,
            error: !!errorMessage,
            disabled,
          })}
        >
          <div className="flex flex-wrap gap-1 w-full mr-1">{renderTags()}</div>
          <div className="flex items-center min-w-[20%] w-full">
            <input
              ref={ref}
              className={s.input({ isFocused: isOpen })}
              {...getInputProps({
                disabled,
                onKeyDown(event) {
                  if (event.key === "Backspace" && !value) {
                    removeSelectedOption(
                      selectedOptions[selectedOptions.length - 1]
                    );
                  }
                },
              })}
              placeholder={placeholder}
            />
            <div
              className="w-6 h-6 flex items-center ml-3"
              {...getToggleButtonProps({ disabled })}
            >
              <Icon
                name={`keyboard_arrow_${!isOpen ? "down" : "up"}`}
                color="th-color-neutral-600"
                size="size-x6"
              />
            </div>
          </div>
        </div>
        {errorMessage && (
          <div className="flex items-center mt-1 gap-1">
            <Icon name="error" color="th-error-light" size="size-x3" />
            <Text
              type="paragraphXS"
              color="th-error-light"
              className="text-[10px] leading-none"
            >
              {errorMessage}
            </Text>
          </div>
        )}
        <ul
          {...getMenuProps(
            getDropdownProps({
              className:
                isOpen && options.length ? s.dropdownContainer() : "hidden",
            })
          )}
        >
          {options.map((option, index) => (
            <li
              className={s.option({ highlighted: highlightedIndex === index })}
              key={`${option.value}-${index}`}
            >
              <Checkbox
                {...getItemProps({ index, item: option })}
                checked={isSelected(option)}
                disabled={disabled}
                onChange={() => {
                  if (isSelected(option)) {
                    removeSelectedOption(option);
                  } else {
                    setSelectedOptions([...selectedOptions, option]);
                  }
                }}
              />
              <Text type="paragraphM" color="th-color-neutral-700">
                {option.label}
              </Text>
            </li>
          ))}
        </ul>
      </div>
    );
  }
);

export { Multiselect };
