import { ClipLoader } from "react-spinners";
import { Text } from "../text/text";

type LoadingProps = {
  description?: string;
};

const Loading = ({ description }: LoadingProps) => {
  return (
    <div
      className="fixed bottom-0 right-0 left-0 top-0 flex flex-col gap-size-x4 w-full h-full z-[99]
     bg-black opacity-50 items-center justify-center content-center"
    >
      {description && <Text color="th-color-neutral-0">{description}</Text>}
      <ClipLoader color={"#F3F4F6"} size={48} speedMultiplier={0.75} />
    </div>
  );
};

export { Loading };
