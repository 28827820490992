import { cva } from "class-variance-authority";

export const container = cva("flex w-full flex-col", {
  variants: {
    disabled: {
      true: "opacity-level-5",
    },
  },
});
export const textfield = cva(
  "border-border-width-thin rounded-border-radius-small px-size-x2 gap-size-x4 hover:bg-th-color-neutral-0 hover:border-th-primary-color-lightest flex flex-row items-center",
  {
    variants: {
      size: {
        medium: "h-size-x9",
        large: "h-size-x12",
      },
      status: {
        focused: "border-th-primary-color-light",
        default: "border-th-color-neutral-100",
      },
      error: {
        true: "border-th-error",
      },
    },
  }
);
export const input = cva(
  "text-default h-full w-full bg-transparent leading-[18.84px] text-neutral-800 outline-none"
);
export const helperContainer = cva(
  "py-size-base gap-size-base text-small flex flex-row items-center text-neutral-800",
  {
    variants: {
      error: {
        true: "text-th-error-light",
      },
    },
  }
);
