import { cva } from "class-variance-authority";

export const selectTriggerClassName = cva(
  "border-border-width-thin border-th-color-neutral-100 pl-size-x3 hover:border-th-primary-color-lightest data-[state=open]:border-th-primary-color-light focus:border-th-primary-color-light hover:bg-th-color-neutral-0 flex w-full transform items-center justify-between rounded-[1px] [&>div:last-child>svg]:data-[state=open]:rotate-180",
  {
    variants: {
      size: {
        medium: "text-medium h-size-x9",
        large: "text-default h-size-x12",
      },
      disabled: {
        true: "pointer-events-none opacity-60",
      },
      error: {
        true: "border-th-error",
      },
    },
  },
);
