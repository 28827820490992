import { create } from "zustand";
import { UserType } from "@aprosoja/types";

export type GetUsersData = {
  total: number;
  limit: number;
  page: number;
  data: UserType[];
};

type UserStore = {
  user?: UserType;
  setUser: (user: UserType) => void;
};

const useUser = create<UserStore>((set) => ({
  setUser: (user) => set({ user }),
}));

export { useUser };
