import * as React from "react";
import { Slot } from "@radix-ui/react-slot";

import { Loading } from "./loading/loading";
import { buttonVariants } from "./button.recipe";
import type { ButtonProps } from "./button.types";

import { Icon } from "../icon/icon";

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      asChild = false,
      variant = "contained",
      color = "primary",
      size = "medium",
      disabled = false,
      children,
      loading,
      startIcon,
      endIcon,
      type = "button",
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : "button";
    const iconSize = () => {
      switch (size) {
        case "small":
          return "size-x4";
        case "medium":
          return "size-x5";
        case "large":
          return "size-x6";
      }
    };
    const iconColor = () => {
      if (variant === "contained" && color !== "secondary") {
        return "th-color-neutral-0";
      } else if (variant === "contained" && color === "secondary") {
        return "th-content-secondary-light";
      } else {
        switch (color) {
          case "primary":
            return "th-primary-color-main";
          case "secondary":
            return "th-tertiary-color-main";
          case "success":
            return "th-success-dark";
          case "error":
            return "th-error-dark";
        }
      }
    };

    return (
      <Comp
        data-testid="button"
        className={buttonVariants({
          className,
          variant,
          color,
          size,
          disabled,
        })}
        ref={ref}
        disabled={disabled || loading}
        type={type}
        {...props}
      >
        <>
          {loading && (
            <div className="absolute left-[50%] top-[50%] translate-x-[-50%] translate-y-[-50%]">
              <Loading size={size} color={iconColor()} />
            </div>
          )}
          <div
            className={`gap-size-base flex items-center justify-center ${loading ? "hidden" : "visible"}`}
          >
            {startIcon && (
              <Icon name={startIcon} size={iconSize()} color={iconColor()} />
            )}
            {children}
            {endIcon && (
              <Icon name={endIcon} size={iconSize()} color={iconColor()} />
            )}
          </div>
        </>
      </Comp>
    );
  }
);

Button.displayName = "Button";

export { Button, buttonVariants };
