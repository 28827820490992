import { create } from "zustand";
import { type ToastFeedbackUseType } from "@aprosoja/types";

type ToastFeedbackUserStore = {
  toast: ToastFeedbackUseType[];
  setToast: (data: ToastFeedbackUseType) => void;
  removeToast: (id: string) => void;
};

const useToastFeedbackUser = create<ToastFeedbackUserStore>((set) => ({
  toast: [],

  setToast: (data) =>
    set((state) => {
      return {
        ...state,
        toast: [...state.toast, data],
      };
    }),

  removeToast: (id) =>
    set((state) => {
      const updatedToastList = state.toast.filter(
        (toastFilter) => toastFilter.id !== id
      );
      return {
        ...state,
        toast: updatedToastList,
      };
    }),
}));

export { useToastFeedbackUser };
