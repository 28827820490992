"use client";

import { forwardRef } from "react";
import { useForm, Controller } from "react-hook-form";

import { Button, TextField } from "../index";

type SearchBarProps = {
  placeholder?: string;
  onSearch: (value: string) => void;
  startIcon?: string;
};
type FormValue = {
  search: string;
};

export const SearchBar = forwardRef<HTMLFormElement, SearchBarProps>(
  ({ placeholder = "Pesquisar por nome", onSearch, startIcon }, ref) => {
    const { control, handleSubmit } = useForm<FormValue>({
      defaultValues: {
        search: "",
      },
    });

    const handleSubmitted = ({ search }: { search: string }) => {
      onSearch(search ?? "");
    };

    return (
      <form
        ref={ref}
        onSubmit={handleSubmit(handleSubmitted)}
        className="w-full flex items-center gap-size-x4"
      >
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              placeholder={placeholder}
              className={`border-th-color-neutral-200 bg-th-color-neutral-0
              rounded-border-radius-small`}
              size={"large"}
              startIcon={startIcon}
            />
          )}
        />
        <Button
          type="submit"
          size={"large"}
          className="rounded-border-radius-small mb-size-x2"
        >
          Pesquisar
        </Button>
      </form>
    );
  }
);
