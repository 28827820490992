import { cva } from 'class-variance-authority';

export const container = cva('flex flex-col gap-2 w-full');

export const editorContainer = cva(`relative overflow-hidden w-full h-full
border-solid border border-th-color-neutral-200 rounded-border-radius-small py-size-x2`);

export const headerDocument = cva('flex gap-4 pb-2');

export const errorTextField = cva('flex items-center gap-2 text-xs');
