export const getBadgeCategoryColor = (categoryColor: string): string => {
  switch (categoryColor) {
    case "th-primary-color-main":
      return "th-primary-color-lightest";
    case "th-primary-color-darkest":
      return "th-primary-color-light";
    case "th-secondary-color-dark":
      return "th-secondary-color-lightest";
    case "th-secondary-color-darkest":
      return "th-secondary-color-light";
    case "th-color-neutral-700":
      return "th-color-neutral-0";
    case "th-color-neutral-800":
      return "th-color-neutral-400";
    case "th-color-neutral-900":
      return "th-color-neutral-700";
    default:
      return "th-color-neutral-0";
  }
};
