"use client";

import { forwardRef, ElementRef, useId } from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";

import { Icon } from "../icon/icon";
import { Label } from "../label/label";
import { type CheckboxProps } from "./checkbox.types";
import { outline, root } from "./checkbox.recipe";

const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    {
      className,
      checked,
      disabled,
      label,
      required,
      size = "small",
      onCheckedChange,
      ...props
    },
    ref
  ) => {
    const id = useId();

    return (
      <div className="flex flex-row items-center">
        <div className={outline({ size, disabled })}>
          <CheckboxPrimitive.Root
            id={id}
            checked={checked}
            ref={ref}
            className={root({ isChecked: checked, size, disabled })}
            onCheckedChange={(value) => !disabled && onCheckedChange?.(value)}
            {...props}
          >
            <CheckboxPrimitive.Indicator
              className={"flex items-center justify-center border-neutral-700"}
            >
              <Icon name="check" color="th-color-neutral-0" size="size-x4" />
            </CheckboxPrimitive.Indicator>
          </CheckboxPrimitive.Root>
        </div>
        <div>
          {!!label && (
            <Label
              htmlFor={id}
              required={required}
              size={size === "medium" ? "large" : "default"}
            >
              {label}
            </Label>
          )}
        </div>
      </div>
    );
  }
);
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
