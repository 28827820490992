import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import "dayjs/locale/pt-br";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(updateLocale);

dayjs.updateLocale("pt-br", {
  monthsShort: [
    "JAN",
    "FEV",
    "MAR",
    "ABR",
    "MAI",
    "JUN",
    "JUL",
    "AGO",
    "SET",
    "OUT",
    "NOV",
    "DEZ",
  ],
});

export const formatDate = (date: string) => {
  return dayjs(date).locale("pt-br").format("DD MMM YYYY HH:mm");
};
