import { rem } from "@aprosoja/utils";

export const sizes = {
  "size-base": rem(4),
  "size-x2": rem(8),
  "size-x3": rem(12),
  "size-x4": rem(16),
  "size-x5": rem(20),
  "size-x6": rem(24),
  "size-x7": rem(28),
  "size-x8": rem(32),
  "size-x9": rem(36),
  "size-x10": rem(40),
  "size-x11": rem(44),
  "size-x12": rem(48),
  "size-x13": rem(52),
  "size-x14": rem(56),
  "size-x15": rem(60),
  "size-x16": rem(64),
  "size-x17": rem(68),
  "size-x18": rem(72),
  "size-x19": rem(76),
  "size-x20": rem(80),
  "size-x30": rem(120),
  "size-x40": rem(160),
  "size-x50": rem(200),
};
