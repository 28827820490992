/* eslint-disable max-len */
import { cva } from "class-variance-authority";

export const container = cva("flex w-full flex-col", {
  variants: {
    disabled: {
      true: "opacity-level-5",
    },
  },
});
export const textarea = cva(
  "border-border-width-thin gap-size-x4 hover:bg-th-color-neutral-0 hover:border-th-primary-color-lightest flex flex-row h-full bg-transparent focus:bg-transparent rounded-border-radius-small",
  {
    variants: {
      status: {
        focused: "border-th-primary-color-light",
        default: "border-th-color-neutral-200",
      },
      error: {
        true: "border-th-error",
      },
    },
  }
);
export const input = cva(
  "flex min-h-[80px] rounded-lg px-2 py-2 placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 text-default h-full w-full bg-transparent leading-[18.84px] text-neutral-800 resize-none"
);
export const helperContainer = cva(
  "py-size-base gap-size-base text-nano flex flex-row items-center text-neutral-800",
  {
    variants: {
      error: {
        true: "text-th-error-light",
      },
    },
  }
);
