import { cva } from 'class-variance-authority';

export const modalContentVariants = cva(
  'w-full flex flex-col items-center gap-size-x6 text-center p-size-x6 bg-th-color-neutral-0',
  {
    variants: {
      variant: {
        'alert-action': 'max-w-[480px] rounded-border-radius-giant',
        'alert-message': 'max-w-[480px] rounded-border-radius-giant',
        'success-message': 'max-w-[480px] rounded-border-radius-giant',
        delete: 'max-w-[420px] rounded-border-radius-small',
      },
    },
  },
);
