"use client";

import { api, urls } from "@aprosoja/api";
import { fileToBase64 } from "@aprosoja/utils";

const useEditorHeader = () => {
  const handleAddImage = async (selectedImage?: File) => {
    try {
      const base64Image = await fileToBase64(selectedImage);
      const response = await api.post(urls.file, { file: base64Image });
      if (response.status === 200 || response.status === 201) {
        return response.data;
      }
    } catch (err) {
      return null;
    }
  };

  return {
    handleAddImage,
  };
};

export default useEditorHeader;
