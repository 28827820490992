import { cva } from "class-variance-authority";

export const text = cva("span", {
  variants: {
    type: {
      displayLarge: ["text-giant", "font-bold", "leading-medium"],
      displayMadium: ["text-display", "font-bold", "leading-medium"],
      displaySmall: ["text-hero", "font-bold", "leading-medium"],
      displayXSmall: ["text-xbig", "font-bold", "leading-medium"],
      headingXXL: ["text-hero", "font-bold", "leading-medium"],
      headingXL: ["text-xbig", "font-bold", "leading-large"],
      headingL: ["text-big", "font-bold", "leading-large"],
      headingM: ["text-xlarge", "font-bold", "leading-large"],
      headingS: ["text-large", "font-bold", "leading-large"],
      headingXS: ["text-default", "font-bold", "leading-large"],
      paragraphL: ["text-large", "font-default", "leading-large"],
      paragraphM: ["text-default", "font-default", "leading-large"],
      paragraphS: ["text-medium", "font-default", "leading-large"],
      paragraphXS: ["text-small", "font-default", "leading-large"],
      labelL: ["text-large", "font-medium", "leading-large"],
      labelM: ["text-default", "font-medium", "leading-large"],
      labelS: ["text-medium", "font-medium", "leading-large"],
      labelXS: ["text-small", "font-medium", "leading-large"],
    },
    textAlign: {
      left: ["text-left"],
      center: ["text-center"],
      right: ["text-right"],
    },
  },
  defaultVariants: {
    type: "paragraphL",
    textAlign: "left",
  },
});
