'use client';
import React from 'react';
import * as SelectPrimitive from '@radix-ui/react-select';
import { ChevronDown } from 'lucide-react';

import * as s from './select-trigger.recipe';
import type { SelectTriggerProps } from './select-trigger.types';

const SelectTrigger = React.forwardRef<React.ElementRef<typeof SelectPrimitive.Trigger>, SelectTriggerProps>(
  ({ children, disabled, error, size, className, ...props }, ref) => (
    <SelectPrimitive.Trigger
      ref={ref}
      className={s.selectTriggerClassName({ disabled, error, size, className })}
      {...props}
    >
      <div className="max-w-[calc(var(--radix-select-trigger-width)-24px)] overflow-hidden">{children}</div>
      <SelectPrimitive.Icon asChild>
        <div className="w-size-x6">
          <ChevronDown className="h-size-x6 w-size-x6 opacity-50" />
        </div>
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  ),
);
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

export { SelectTrigger };
