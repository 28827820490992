import { cva } from 'class-variance-authority';

export const root = cva('h-size-x8 gap-size-base flex w-full items-center justify-between');
export const requiredChar = cva('text-small text-th-error-light align-top');
export const supportLabel = cva('text-small align-middle text-neutral-500');
export const label = cva('flex items-center leading-[17.36px]', {
  variants: {
    size: {
      default: 'text-medium',
      medium: 'text-default',
      large: 'text-large',
    },
    status: {
      default: 'text-neutral-700',
      focused: 'text-th-primary-color-main',
    },
  },
});
