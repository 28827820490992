export const radii = {
  "border-radius-none": "0px",
  "border-radius-tiny": "4px",
  "border-radius-small": "8px",
  "border-radius-medium": "12px",
  "border-radius-large": "16px",
  "border-radius-big": "20px",
  "border-radius-giant": "24px",
  "border-radius-pill": "500px",
  "border-radius-full": "50%",
};

export const borderWidths = {
  "border-width-none": "0px",
  "border-width-thin": "1px",
  "border-width-default": "1.5px",
  "border-width-thick": "2px",
};
