import { forwardRef } from "react";

import * as s from "./styles";
import { Icon, Text } from "../index";

type ChipProps = {
  children: string;
  onClosePress?: () => void;
};

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  ({ children, onClosePress }, ref) => {
    return (
      <div ref={ref} className={s.container()}>
        <Text
          type="paragraphM"
          color="th-primary-color-darkest"
          className="text-nowrap"
        >
          {children}
        </Text>
        <button
          onClick={onClosePress}
          type="button"
          className={s.buttonClose()}
        >
          <Icon size="size-x3" name="close" color="th-primary-color-darkest" />
        </button>
      </div>
    );
  }
);
