import { cva } from 'class-variance-authority';

export const wrappedDropzone = cva(
  `h-full w-fit rounded-border-radius-small flex gap-size-x4 items-center cursor-pointer transition-all duration-200
  ease-in-out`,
);

export const deleteIconWrapper = cva('absolute top-3 right-3 z-10');

export const wrappedImage = cva('w-[200px] h-[200px] rounded-border-radius-large relative');
