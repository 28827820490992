export const colors = {
  // custom colors
  "th-primary-color-lightest": "#D6FFE7",
  "th-primary-color-lightest-16": "#D6FFE729",
  "th-primary-color-lightest-24": "#D6FFE73D",
  "th-primary-color-lightest-32": "#D6FFE752",
  "th-primary-color-light": "#ADFFCE",
  "th-primary-color-medium": "#00E05A",
  "th-primary-color-main": "#005924",
  "th-primary-color-darkest": "#002910",
  "th-secondary-color-lightest": "#FEF5D8",
  "th-secondary-color-light": "#FCEDB1",
  "th-secondary-color-main": "#D4AB06",
  "th-secondary-color-dark": "#624F04",
  "th-secondary-color-darkest": "#272001",
  "th-tertiary-color-main": "#111417",
  "th-color-neutral-900": "#111417",
  "th-color-neutral-800": "#20252B",
  "th-color-neutral-700": "#2B323B",
  "th-color-neutral-600": "#2B323B",
  "th-color-neutral-400": "#5E6D82",
  "th-color-neutral-200": "#D0D4DD",
  "th-color-neutral-100": "#E8E9EE",
  "th-color-neutral-0": "#F3F4F6",
  "th-error-light": "#FF6354",
  "th-error": "#C20A0A",
  "th-info": "#1F1FFF",
  "th-bg-light": "#E8E9EE",
  "th-content-primary-light": "#F3F4F6",

  // senna colors
  "th-tertiary-color-lightest": "#FFF0B2",
  "th-tertiary-color-light": "#FFDC4D",
  "th-tertiary-color-dark": "#B28F01",
  "th-tertiary-color-darkest": "#261F00",
  "th-color-neutral-500": "#777780",
  "th-color-neutral-300": "#A7A6B2",
  "th-success-dark": "#0B6B37",
  "th-success-light": "#12B25B",
  "th-info-dark": "#1433CC",
  "th-info-light": "#788EFF",
  "th-alert-dark": "#6E5D0B",
  "th-alert-light": "#CCAD14",
  "th-error-dark": "#B22012",
  "th-bg-dark": "#18181A",
  "th-content-secondary-light": "#18181A",
  "th-content-tertiary-light": "#18181A",
  "th-content-background-light": "#18181A",
  "th-content-surface-light": "#18181A",
  "th-content-feedback-light": "#EEEDFF",
  "th-content-background-dark": "#EEEDFF",
  "th-content-surface-dark": "#EEEDFF",
  "th-content-feedback-dark": "#18181A",
};
