import {
  formatCEP,
  formatCPF,
  formatCNPJ,
} from "@brazilian-utils/brazilian-utils";

export { formatCEP, formatCPF, formatCNPJ };

export const formatCurrency = (value: string | undefined) => {
  if (!value) return "";
  return new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: 2,
    style: "currency",
    currency: "BRL",
  }).format(Number(value));
};

export const formatDecimal = (value: string, fractionDigits?: number) => {
  if (!value) return "";
  return new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: fractionDigits ?? 2,
  }).format(Number(value));
};

export const formatRawNumber = (value: string): string | undefined => {
  if (!value) return "";
  value = value.replace(/\D/g, "");
};

export const formatNumber = (value: string) => {
  if (!value) return "";
  return new Intl.NumberFormat("pt-BR", {
    maximumFractionDigits: 0,
  }).format(Number(value));
};

export const formatCpfOrCnpj = (text: string): string | undefined => {
  if (!text) return "";
  text = text.replace(/\D/g, "");

  if (text.length <= 11) {
    return formatCPF(text);
  } else {
    return formatCNPJ(text);
  }
};

export const formatCellPhone = (text: string): string | undefined => {
  if (!text) return "";
  return text
    .replace(/\D/g, "")
    .replace(/(\d{0})(\d)/, "$1($2")
    .replace(/(\d{2})(\d)/, "$1) $2")
    .replace(/(\d{1})(\d+?$)/, "$1 $2")
    .replace(/(\d{4})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{4})\d+?$/, "$1");
};

export const formatPhone = (text: string): string | undefined => {
  if (!text) return "";
  text = text.replace(/\D/g, "");

  if (text.length <= 10) {
    return text
      .replace(/(\d{0})(\d)/, "$1($2")
      .replace(/(\d{2})(\d)/, "$1) $2")
      .replace(/(\d{4})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  } else {
    return text
      .replace(/(\d{0})(\d)/, "$1($2")
      .replace(/(\d{2})(\d)/, "$1) $2")
      .replace(/(\d{1})(\d+?$)/, "$1 $2")
      .replace(/(\d{4})(\d{1,2})/, "$1-$2")
      .replace(/(-\d{4})\d+?$/, "$1");
  }
};

export const formatCreditCard = (text: string): string | undefined => {
  if (!text) return "";
  return text
    .replace(/\D/g, "")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d)/, "$1 $2")
    .replace(/(\d{4})(\d+?)$/, "$1");
};

export const formatMonthYear = (text: string): string | undefined => {
  if (!text) return "";
  return text
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1/$2")
    .replace(/(\/\d{4})\d+?$/, "$1");
};

export const removeMask = (text: string) => {
  return text
    .replace(/\D/g, "")
    .replace(/[áàãâä]/gi, "a")
    .replace(/[éèêë]/gi, "e")
    .replace(/[íìîï]/gi, "i")
    .replace(/[óòõôö]/gi, "o")
    .replace(/[úùûü]/gi, "u")
    .replace(/[ç]/gi, "c");
};

export const formatRG = (value: string) => {
  if (!value) return "";
  const cleanedValue = value.replace(/\D/g, "");

  const formattedRG = cleanedValue
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1-$2");

  return formattedRG;
};
